import React, { useRef, useState, useEffect } from 'react';
import { motion } from "framer-motion";

import './WebsiteInfo.css';
import closePopupIcon from '../icons/deleteIcon.png';

const WebsiteInfo = (props) => {
  const { 
    onClickOutside, 
    closePopup, 
    show, 
    storeLogo, 
    store, 
    storeDescription, 
    socialMediaLinks, 
    websiteColor,
    isLightBackground 
  } = props;
  
  const websiteInfoRef = useRef();

  // State to track if the store logo image exists
  const [imageExists, setImageExists] = useState(false);

  // Handle clicks outside the popup
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (websiteInfoRef.current && !websiteInfoRef.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  // Preload the store logo image and verify its existence
  useEffect(() => {
    // If storeLogo is "defaultLogo", do not load any image
    if (storeLogo === 'defaultLogo') {
      setImageExists(false);
      return;
    }

    if (storeLogo) {
      const img = new Image();
      img.src = storeLogo;
      img.onload = () => setImageExists(true);   // Image loaded successfully
      img.onerror = () => setImageExists(false);   // Image failed to load
    } else {
      setImageExists(false); // No image URL provided
    }
  }, [storeLogo]);

  // Early return if the popup shouldn't be shown
  if (!show)
    return null;

  // Normalize and filter socialMediaLinks to include only non-empty strings
  let socialMediaLinksArray = [];
  if (Array.isArray(socialMediaLinks)) {
    // Extract URLs from objects or keep strings as is
    socialMediaLinksArray = socialMediaLinks
      .map(link => {
        if (typeof link === 'string') {
          return link;
        } else if (link && typeof link === 'object' && link.url) {
          return link.url;
        }
        return null;
      })
      .filter(link => link && link.trim() !== '');
  } else if (typeof socialMediaLinks === 'string') {
    const trimmedLink = socialMediaLinks.trim();
    if (trimmedLink !== '') {
      socialMediaLinksArray = [trimmedLink];
    }
  }

  // Array of social platforms you want to detect
  const socialPlatforms = [
    'instagram',
    'facebook',
    'tiktok',
    'linkedin',
    'twitter',
    'snapchat',
    'youtube',
    'pinterest',
    'yaga'
    // Add or remove as needed
  ];

  return (
    <motion.div 
      className="websiteInfoDiv" 
      ref={websiteInfoRef}
      initial={{ y: '100vh' }} // Start below the screen
      animate={{ y: 0 }} // Slide it into view
      transition={{ 
        duration: 0.5, 
        ease: "easeInOut" 
      }}
      style={{
        background: isLightBackground ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.5)',
      }}
    >
      {/* Close Popup Button */}
      <img 
        className="websiteInfoClose"
        src={closePopupIcon} 
        alt="Close" 
        onClick={closePopup}
      />

      <div className="websiteInfoContainer">
        {/* Only show the logo if storeLogo is not "defaultLogo" and the image exists */}
        {storeLogo !== 'defaultLogo' && imageExists && (
          <img 
            src={storeLogo} 
            alt={`${store} Logo`} 
            className="websiteStoreLogo" 
          />
        )}

        <div className="websiteStoreName">
          {store}
        </div>

        <div className="websiteDescription">
          {storeDescription}
        </div>

        {socialMediaLinksArray.length > 0 && (
          <div className="websiteSocialMediaLinks">
            {socialMediaLinksArray.map((link, index) => {
              const lowerCaseLink = link.toLowerCase();
              
              // Check if the link matches any of your social platforms
              const matchedPlatform = socialPlatforms.find(platform => 
                lowerCaseLink.includes(platform)
              );

              if (matchedPlatform) {
                // Capitalize the social platform’s name for display
                const displayName = matchedPlatform.charAt(0).toUpperCase() + matchedPlatform.slice(1);

                return (
                  <button
                    key={index}
                    className="websiteSocialMediaLinkDiv"
                    onClick={() => {
                      // Ensure the URL has a protocol
                      const formattedLink = lowerCaseLink.startsWith('http://') || lowerCaseLink.startsWith('https://') ? link : `https://${link}`;
                      // Open the link in a new tab
                      window.open(formattedLink, '_blank');
                      // Optionally close the popup
                      if (closePopup) closePopup();
                    }}
                  >
                    {displayName}
                  </button>
                );
              } else {
                // Default to showing "Website Link" if it doesn't match
                return (
                  <button
                    key={index}
                    className="websiteSocialMediaLinkDiv"
                    onClick={() => {
                      // Ensure the URL has a protocol
                      const formattedLink = lowerCaseLink.startsWith('http://') || lowerCaseLink.startsWith('https://') ? link : `https://${link}`;
                      // Open the link in a new tab
                      window.open(formattedLink, '_blank');
                      // Optionally close the popup
                      if (closePopup) closePopup();
                    }}
                  >
                    Website Link
                  </button>
                );
              }
            })}
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default WebsiteInfo;
