import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';

import { VendorFormContext } from '../context/VendorFormContext.jsx';
import { motion, AnimatePresence } from 'framer-motion'; // Import Framer Motion components

const VendorFormStep2 = ({ next, prev, setAlertMessage, setAlertType }) => {
  const { formData, updateFormData } = useContext(VendorFormContext);

  const [isFormValid, setIsFormValid] = useState(false);
  const [showContainer2, setShowContainer2] = useState(false); // State to control visibility of container2

  const goalsExpectationsOptions = [
    { label: "Increase Online Sales", value: "Increase Online Sales" },
    { label: "Expand Customer Base", value: "Expand Customer Base" },
    { label: "Improve Brand Awareness", value: "Improve Brand Awareness" },
    { label: "Enhance Customer Engagement", value: "Enhance Customer Engagement" },
    { label: "Streamline Operations", value: "Streamline Operations" },
    { label: "Launch New Products/Services", value: "Launch New Products/Services" },
    { label: "Other", value: "Other" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateFormData({ [name]: value });
  };

  // Handle "Other" option for goals and expectations
  useEffect(() => {
    if (formData.goalsExpectations !== 'Other') {
      updateFormData({ goalsExpectationsOther: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.goalsExpectations]);

  // Animation variants for the second container
  const containerVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: { y: 0, opacity: 1 },
    exit: { y: 50, opacity: 0 },
  };

  // Validation Logic
  useEffect(() => {
    const validateContainer1 = () => {
      const {
        operationalDuration,
        businessLocationType,
        goalsExpectations,
        goalsExpectationsOther,
      } = formData;

      // Check required fields in container1 are not empty
      if (
        !operationalDuration.trim() ||
        !businessLocationType.trim() ||
        !goalsExpectations.trim() ||
        (goalsExpectations === 'Other' && !goalsExpectationsOther.trim())
      ) {
        return false;
      }

      return true;
    };

    const isContainer1Valid = validateContainer1();
    setShowContainer2(isContainer1Valid);

    const validateForm = () => {
      const { describeBrand, keyWords } = formData;

      if (!describeBrand.trim() || !keyWords.trim()) {
        return false;
      }

      return true;
    };

    setIsFormValid(isContainer1Valid && validateForm());
  }, [formData]);

  const handleNext = (e) => {
    e.preventDefault();
    // If 'Other' is selected, ensure the additional field is filled
    if (formData.goalsExpectations === 'Other' && !formData.goalsExpectationsOther.trim()) {
      setAlertMessage('Please specify your goals and expectations.');
      setAlertType('error')
      return;
    }
    next();
  };

  const handlePrevClick = (e) => {
    e.preventDefault();
    prev();
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      position: 'relative',
      backgroundColor: '#f5f5f5',
      height: '30px',
      borderRadius: '10px',
      marginTop: '10px',
      width: '100%',
      fontFamily: 'Poppins',
      fontSize: '13.5px',
      zIndex: 1001,
      boxShadow: '-2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45)',
      borderColor: 'none',
      border: 'none',
    }),
    menu: (base) => ({
      ...base,
      position: 'absolute',
      backgroundColor: '#f5f5f5',
      top: '20px',
      marginBottom: '-24px',
      borderRadius: '0px 0px 12px 12px',
      fontFamily: 'Poppins',
      fontSize: '12px',
      zIndex: 1000,
      paddingTop: '15px',
      overflow: 'scroll'    
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? '#FB890E' : (state.isFocused ? '#FFC55C' : base.backgroundColor),
      color: state.isSelected || state.isFocused ? 'rgba(0, 0, 0, 0.8)' : base.color,
      width: '98%',
      margin: '2px auto',
      boxSizing: 'border-box',
      overflow: 'hidden',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      textAlign: 'left',
    }),
    singleValue: (base) => ({
      ...base,
      color: '#333',
    }),
  };

  return (
    <form onSubmit={handleNext}>
      <div className="vendorFormStep2Container1">
        <div className="operationalDurationDiv">
          <label htmlFor="operationalDuration">
            How long has your business been running for?
          </label>
          <input
            type="text"
            name="operationalDuration"
            id="operationalDuration"
            value={formData.operationalDuration}
            onChange={handleChange}
            placeholder="e.g., 2 years"
            required
          />
        </div>
        <div className="businessLocationTypeDiv">
          <label>
            How do you run your business?
          </label>
          <div className="businessLocationType">
            <button
              type="button"
              onClick={() => handleChange({ target: { name: 'businessLocationType', value: 'Physical Storefront' } })}
              className={formData.businessLocationType === 'Physical Storefront' ? 'buttonSelected' : ''}
            >
              physical shop
            </button>
            <button
              type="button"
              onClick={() => handleChange({ target: { name: 'businessLocationType', value: 'Online Only' } })}
              className={formData.businessLocationType === 'Online Only' ? 'buttonSelected' : ''}
            >
              online
            </button>
            <button
              type="button"
              onClick={() => handleChange({ target: { name: 'businessLocationType', value: 'Both' } })}
              className={formData.businessLocationType === 'Both' ? 'buttonSelected' : ''}
            >
              both
            </button>
          </div>
        </div>

        <div className="goalsExpectationsDiv">
          <label htmlFor="goalsExpectations">
            What are your expectations and primary business goals when joining <strong>taizte?</strong>
          </label>

          <Select
            name="goalsExpectations"
            options={goalsExpectationsOptions}
            value={goalsExpectationsOptions.find(option => option.value === formData.goalsExpectations)}
            onChange={(selectedOption) => 
              updateFormData({ goalsExpectations: selectedOption?.value || '' })
            }
            placeholder="Select"
            styles={customStyles}
            required
            isSearchable
          />
        </div>
        {formData.goalsExpectations === 'Other' && (
          <div className="goalsExpectationsOtherDiv">
            <label htmlFor="goalsExpectationsOther">
              Please specify your goals and expectations:
            </label>
            <input
              type="text"
              name="goalsExpectationsOther"
              id="goalsExpectationsOther"
              value={formData.goalsExpectationsOther || ''}
              onChange={handleChange}
              required={formData.goalsExpectations === 'Other'}
            />
          </div>
        )}
      </div>

      {/* AnimatePresence handles the mounting and unmounting animations for container2 */}
      <AnimatePresence>
        {showContainer2 && (
          <motion.div
            className="vendorFormStep2Container2"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.5, ease: 'easeOut' }}
          >
            <textarea
              name="describeBrand"
              id="describeBrand"
              value={formData.describeBrand}
              onChange={handleChange}
              placeholder="Describe your brand's aesthetic/visuals/vibe:"
              required
            />

            {/* Key Words */}
            <div className="keyWordsDiv">
              <label htmlFor="businessKeyWords">
                Which keywords describe your business best:
              </label>
              <textarea
                name="keyWords"
                id="businessKeyWords"
                value={formData.keyWords}
                onChange={handleChange}
                rows="3"
                placeholder="e.g., Innovative, Reliable, Customer-Centric"
                required
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="storelessUserBtnDiv2">
        <button 
          type="button" 
          className="storelessUserBackBtn" 
          onClick={handlePrevClick}
        >
          back
        </button>
        <button 
          type="submit" 
          className="storelessUserNextBtn" 
          disabled={!isFormValid} // Disable button if form is invalid
        >
          next
        </button>
      </div>
    </form>
  );
};

export default VendorFormStep2;
