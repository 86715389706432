import React, { useState, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { doc, updateDoc, getDoc } from '@firebase/firestore';
import { storage, db } from '../../firebase';
import { motion, AnimatePresence } from 'framer-motion';
import deleteIcon from '../../icons/deleteIcon.png';
import plusIcon from '../../icons/plusIcon.png';
import defaultStoreLogo from '../../images/defaultStoreLogo.png';
import './LogoUploadPopup.css'; // Ensure you have appropriate styles

const LogoUploadPopup = ({
  userID,
  storeID,
  currentLogo,
  onClose,
  onLogoUpdate,
  setAlertMessage,
  setAlertType,
}) => {
  // Initialize preview:
  // If currentLogo equals "defaultLogo", show the defaultStoreLogo image.
  const [preview, setPreview] = useState(
    currentLogo === 'defaultLogo' ? defaultStoreLogo : currentLogo || ''
  );
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [error, setError] = useState('');
  const [fileName, setFileName] = useState('');

  // Fetch the current fileName from Firestore
  useEffect(() => {
    const fetchFileName = async () => {
      if (!storeID) return;
      try {
        const storeDoc = await getDoc(doc(db, 'stores', storeID));
        if (storeDoc.exists()) {
          const storeData = storeDoc.data();
          setFileName(storeData.fileName || '');
        } else {
          console.log('No such store document!');
        }
      } catch (err) {
        console.error('Error fetching store data:', err);
        setError('Failed to fetch store data. Please try again.');
      }
    };

    fetchFileName();
  }, [storeID]);

  // Handle file selection
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // File size validation: 5 MB limit
      if (selectedFile.size > 5 * 1024 * 1024) {
        setError('File size exceeds 5 MB. Please select a smaller file.');
        e.target.value = '';
        return;
      }
      setFile(selectedFile);
      setPreview(URL.createObjectURL(selectedFile));
      setError('');
    }
  };

  // Handle logo upload
  const handleUpload = async () => {
    if (!file) {
      setError('Please select a file to upload.');
      return;
    }

    try {
      setIsUploading(true);

      // Generate a unique file name
      const uniqueFileName = `${file.name}_${Date.now()}`;
      const storageRefLogo = ref(storage, `store_logo/${uniqueFileName}`);
      const uploadTask = uploadBytesResumable(storageRefLogo, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setPercentage(progress);
        },
        (uploadError) => {
          console.error('Upload error:', uploadError);
          setError('Error uploading file. Please try again.');
          setIsUploading(false);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          // Update Firestore with the new logo URL and fileName
          const storeRef = doc(db, 'stores', storeID);
          const userRef = doc(db, 'users', userID);
          await updateDoc(storeRef, {
            store_logo: downloadURL,
            fileName: uniqueFileName,
            updated_at: new Date(), // Optional: Track when the logo was updated
          });
          await updateDoc(userRef, {
            store_logo: downloadURL,
            fileName: uniqueFileName,
            updated_at: new Date(),
          });

          // Delete the old logo's original and resized images only if it wasn't the default
          if (currentLogo && currentLogo !== 'defaultLogo' && fileName) {
            try {
              // Delete original logo
              const deleteRefOriginal = ref(storage, `store_logo/${fileName}`);
              await deleteObject(deleteRefOriginal);
              console.log('Old original logo deleted successfully.');

              // Delete resized logo
              const suffix = '_1000x1000';
              const deleteRefResized = ref(storage, `store_logo/${fileName}${suffix}`);
              await deleteObject(deleteRefResized);
              console.log('Old resized logo deleted successfully.');
            } catch (deleteError) {
              console.error('Error deleting old logo:', deleteError);
              // Not critical, so we won't show an error to the user
            }
          }

          setIsUploading(false);
          setAlertMessage('Logo updated successfully!');
          setAlertType('success');
          onLogoUpdate(downloadURL); // Update the Dashboard with the new logo
          onClose(); // Close the popup
        }
      );
    } catch (uploadError) {
      console.error('Error during upload:', uploadError);
      setError('An error occurred during upload. Please try again.');
      setIsUploading(false);
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        className="logoUploadOverlay"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div
          className="logoUploadModal"
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0.8 }}
        >
          <div className="logoUploadHeading">business logo</div>
          <div className="logoPreview">
            {preview ? (
              <img src={preview} alt="Logo Preview" className="logoImagePreview" />
            ) : (
              <p>No logo uploaded yet.</p>
            )}
          </div>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            id="logoUploadInput"
            style={{ display: 'none' }}
          />
          {error && <div className="errorMessage">{error}</div>}
          <div className="uploadActions">
            <img
              src={deleteIcon}
              onClick={onClose}
              disabled={isUploading}
              alt=""
              className="dashboardUploadCloseIcon"
            />
            <label htmlFor="logoUploadInput" className="uploadButton">
              {preview ? 'change logo' : 'upload logo'}
              <img
                className="dashboardUploadButtonIcon"
                src={plusIcon}
                alt="Upload Icon"
              />{' '}
            </label>
            <button
              className="dashboardUploadSaveBtn"
              onClick={handleUpload}
              disabled={!file || isUploading}
            >
              {isUploading
                ? `uploading (${Math.round(percentage)}%)`
                : 'save'}
            </button>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default LogoUploadPopup;
