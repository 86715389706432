// BundlingDiv.jsx

import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from '@firebase/firestore';
import { db } from '../../firebase';
import editIcon from '../../icons/editIcon.png';
import dropdownIcon from '../../icons/popupIcon.png';
import aramexLogo from '../../images/aramexLogo.png';
import pudoLogo from '../../images/pudoLogo.png';
import paxiLogo from '../../images/paxiLogo.png';

import Select from 'react-select';  // <-- IMPORT REACT-SELECT
import './Dashboard.css';
import './BundlingDiv.css';

// Shipping information array imported
import { shippingInformation } from '../../cart/ShippingInformation';

const BundlingDiv = ({ storeID, userProducts }) => {
  const [bundledProducts, setBundledProducts] = useState([]);
  const [showBundledProducts, setShowBundledProducts] = useState(false);
  const [showBundlingSettings, setShowBundlingSettings] = useState(true);
  const [initialBundlingRules, setInitialBundlingRules] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [bundlingRules, setBundlingRules] = useState({});
  const [openDropdown, setOpenDropdown] = useState('');

  // -- ADD THIS STATE to mirror how Pudo/Paxi is handled in ShippingOptions.jsx
  const [selectedPaxiOption, setSelectedPaxiOption] = useState(null);

  // Maps the shipping method logo key to the actual image import
  const logoMap = {
    'aramexLogo': aramexLogo,
    'pudoLogo': pudoLogo,
    'paxiLogo': paxiLogo,
  };

  // Create default bundling rules from shippingInformation, stored as strings.
  // This lets you show an empty field (and the placeholder) when backspacing.
  useEffect(() => {
    const defaultRules = {};
    shippingInformation.forEach((method) => {
      if (method.options && method.options.length > 0) {
        method.options.forEach((opt) => {
          defaultRules[opt.value] = "1";
        });
      }
      if (method.subgroups && method.subgroups.length > 0) {
        method.subgroups.forEach((sub) => {
          sub.options.forEach((opt) => {
            defaultRules[opt.value] = "1";
          });
        });
      }
    });
    setBundlingRules(defaultRules);
    setInitialBundlingRules(defaultRules);
  }, []);

  // Fetch bundling rules from Firestore if they exist and convert them to strings
  useEffect(() => {
    if (storeID) {
      const fetchBundlingRules = async () => {
        const dbRef = doc(db, "stores", storeID);
        const docSnap = await getDoc(dbRef);
        if (docSnap.exists()) {
          const savedRules = docSnap.data().bundlingRules || {};
          const convertedRules = {};
          Object.keys(savedRules).forEach(key => {
            convertedRules[key] = String(savedRules[key]);
          });
          setBundlingRules((prev) => ({ ...prev, ...convertedRules }));
          setInitialBundlingRules((prev) => ({ ...prev, ...convertedRules }));
        } else {
          console.log("No such document!");
        }
      };
      fetchBundlingRules();
    }
  }, [storeID]);

  // Filter bundled products based on userProducts prop
  useEffect(() => {
    if (userProducts) {
      const bundled = userProducts.filter(product => product.bundling === 'yes');
      setBundledProducts(bundled);
    }
  }, [userProducts]);

  const toggleDropdown = (dropdown) => {
    if (openDropdown === dropdown) {
      setOpenDropdown('');
    } else {
      setOpenDropdown(dropdown);
    }
  };

  const saveBundlingRules = async () => {
    if (storeID) {
      const dbRef = doc(db, "stores", storeID);
      // Convert bundlingRules to numbers (ensuring a minimum of 1) before saving
      const numericRules = {};
      Object.keys(bundlingRules).forEach(key => {
        const val = parseInt(bundlingRules[key], 10);
        numericRules[key] = (!val || val < 1) ? 1 : val;
      });
      await updateDoc(dbRef, {
        bundlingRules: numericRules,
      });
    }
    setIsEditMode(false);
  };

  const cancelChanges = () => {
    setBundlingRules(initialBundlingRules);
    setIsEditMode(false);
  };

  // Simply store the raw (string) value from the input.
  const handleInputChange = (valueKey, newValue) => {
    setIsEditMode(true);
    setBundlingRules({
      ...bundlingRules,
      [valueKey]: newValue,
    });
  };

  // A helper to get the correct className for Pudo or Paxi subgroups
  const getSubgroupOptionsClass = (methodName, subgroupName) => {
    if (methodName === "Pudo") {
      switch (subgroupName) {
        case "Locker to Locker":
          return "pudoBundlingOptionsLockerToLocker";
        case "Door to Locker":
          return "pudoBundlingOptionsDoorToLocker";
        case "Locker to Door":
          return "pudoBundlingOptionsLockerToDoor";
        default:
          return "";
      }
    }
    if (methodName === "Paxi") {
      // We'll treat "Standard Delivery" as "Slow Delivery" classes, to match your requirement
      if (subgroupName === "Fast Delivery") {
        return "paxiBundlingOptionsFastDelivery";
      } else if (subgroupName === "Standard Delivery" || subgroupName === "Slow Delivery") {
        return "paxiBundlingOptionsSlowerDelivery";
      }
      return "";
    }
    return "";
  };

  return (
    <div className="bundlingSettingsDiv">
      <div 
        className="bundlingSettingsHeader"
        style={{ justifyContent: isEditMode ? 'space-between' : 'center'}}
      >
        bundling
        {showBundlingSettings &&
          (isEditMode ?
            <div className="bundlingEditButtonContainer">
              <div className="bundlingEditSaveButton" onClick={saveBundlingRules}>save</div>
              <div className="bundlingEditCancelButton" onClick={cancelChanges}>cancel</div>
            </div>
            :
            <img
              className="bundlingEditButton"
              src={editIcon}
              alt="Edit Icon"
              onClick={() => {
                setIsEditMode(true);
                setInitialBundlingRules(bundlingRules);
              }}
            />
          )
        }
      </div>
      <div className="bundlingDivContent">
        <div
          className="bundlingOptionsButtonsDiv"
          style={{ marginTop: isEditMode ? '60px' : '40px' }}
        >
          <div
            className={`bundlingSettingsBtn${showBundlingSettings ? 'Active' : ''}`}
            onClick={() => {
              setShowBundlingSettings(true);
              setShowBundledProducts(false);
            }}
          >
            settings
          </div>
          <div
            className={`bundlingProductsBtn${showBundledProducts ? 'Active' : ''}`}
            onClick={() => {
              setShowBundledProducts(true);
              setShowBundlingSettings(false);
            }}
          >
            products
          </div>
        </div>
        <div className="bundlingSettingsDescriptionDiv">
          <div className="bundlingDescriptionText">
            {showBundlingSettings && "How many of your products can roughly fit into each package’s dimensions?"}
            {showBundledProducts && "These are all your products with bundling enabled"}
          </div>
        </div>

        {showBundledProducts &&
          <div className="bundlingProductsDiv">
            {bundledProducts.map((object, index) => (
              <div className="bundledStoresProducts" key={index}>
                <div className="storesProductsSection1">
                  <img src={object.image} className="storesProductImg" alt={object.productName} />
                  <div className="storesProductInfo">
                    <div className="storesProductName">{object.productName}</div>
                    <div className="storesProductPrice">R{object.listed_price}</div>
                  </div>
                </div>
                <div className="storesProductsSection2">
                  <div className="storesProductBundlingQuantity">{object.quantity}</div>
                </div>
              </div>
            ))}
          </div>
        }

        {showBundlingSettings &&
          <div className="bundlingSettingContainer">
            {/* Dynamically render each shipping method */}
            {shippingInformation.map((method, mIndex) => {
              const methodLogo = logoMap[method.logo];

              // Handle Aramex
              if (method.name === 'Aramex') {
                return (
                  <div className="aramexBundling" key={mIndex}>
                    {methodLogo && <img className="aramexBundlingLogo" src={methodLogo} alt={method.name} />}
                    <div className="aramexBundlingOptions">
                      {method.options.map((opt, oIndex) => (
                        <label className="aramexOption1" key={oIndex}>
                          <div className="aramexOption1Container">
                            <p className="aramexBundling1Text">{opt.description} ({opt.dimensions})</p>
                            <p className="aramexBundlingPrice">{opt.price}</p>
                          </div>
                          <input
                            type="number"
                            min="1"
                            placeholder="1"
                            id="dashboardBundlingBox"
                            value={bundlingRules[opt.value] ?? ""}
                            onChange={(e) => handleInputChange(opt.value, e.target.value)}
                            onBlur={(e) => {
                              if (e.target.value === "" || parseInt(e.target.value, 10) < 1) {
                                setBundlingRules(prev => ({ ...prev, [opt.value]: "1" }));
                              }
                            }}
                          />
                        </label>
                      ))}
                    </div>
                  </div>
                );
              }

              // Handle Pudo (expanding dropdown style)
              if (method.name === 'Pudo') {
                return (
                  <div className="pudoBundling" key={mIndex}>
                    {methodLogo && <img className="pudoBundlingLogo" src={methodLogo} alt={method.name} />}
                    <div className="pudoBundlingOptionsDiv">
                      {method.subgroups.map((sub, sIndex) => {
                        const dropdownKey = `${method.name}-${sub.subgroup}-${sIndex}`;
                        return (
                          <div className="pudoBundlingSelections" key={sIndex} onClick={() => toggleDropdown(dropdownKey)}>
                            <div className="pudoSelectionsHeader">
                              {sub.subgroup}
                              <img
                                className={openDropdown === dropdownKey ? "bundlingDropdownIcon2" : "bundlingDropdownIcon"}
                                src={dropdownIcon}
                                alt="dropdown icon"
                              />
                            </div>
                            {openDropdown === dropdownKey &&
                              <div className={getSubgroupOptionsClass(method.name, sub.subgroup)}>
                                {sub.options.map((opt, optIndex) => (
                                  <div className="pudoOption" key={optIndex} onClick={(e) => e.stopPropagation()}>
                                    <div className={`pudoOption${optIndex + 1}Container`}>
                                      <p className="pudoBundlingText">{opt.description.toLowerCase()}</p>
                                      <p className={`pudoOption${optIndex + 1}Dimensions`}>{`(${opt.dimensions})`}</p>
                                      <p className="pudoBundlingPrice">{opt.price}</p>
                                    </div>
                                    <input
                                      type="number"
                                      min="1"
                                      placeholder="1"
                                      id="dashboardBundlingBox"
                                      value={bundlingRules[opt.value] ?? ""}
                                      onChange={(e) => handleInputChange(opt.value, e.target.value)}
                                      onBlur={(e) => {
                                        if (e.target.value === "" || parseInt(e.target.value, 10) < 1) {
                                          setBundlingRules(prev => ({ ...prev, [opt.value]: "1" }));
                                        }
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                            }
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              }

              // Handle Paxi with a Select (similar style to Pudo's Select)
              if (method.name === 'Paxi') {
                // Build your dropdown options from the subgroups:
                const paxiSubgroups = method.subgroups || [];
                // Rename "Standard Delivery" to "Slow Delivery" for display:
                const paxiSelectOptions = paxiSubgroups.map(sub => ({
                  label: sub.subgroup === "Standard Delivery" ? "Slow Delivery" : sub.subgroup,
                  value: sub.subgroup
                }));

                return (
                  <div className="paxiBundling" key={mIndex}>
                    {methodLogo && <img className="paxiBundlingLogo" src={methodLogo} alt={method.name} />}
                    <div className="paxiBundlingOptionsDiv">
                      
                      {/* The "Select" for Paxi */}
                      <Select
                        className="paxiSelectionsHeader" 
                        options={paxiSelectOptions}
                        onChange={(option) => {
                          setSelectedPaxiOption(option);
                        }}
                        placeholder="Select Paxi Option..."
                        styles={{
                          container: (base) => ({
                            ...base,
                            width: '100%',
                          }),
                          control: (base) => ({
                            ...base,
                            backgroundColor: 'rgb(241, 240, 240)',
                            borderRadius: '10px',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            border: 'none',
                            boxShadow: '-2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45)',
                          }),
                          menu: (base) => ({
                            ...base,
                            backgroundColor: 'rgb(241, 240, 240)',
                            borderRadius: '0px 0px 12px 12px',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            marginTop: '-1px',
                          }),
                          option: (base, state) => ({
                            ...base,
                            backgroundColor: state.isSelected ? '#FB890E'
                              : state.isFocused ? '#FFC55C'
                              : base.backgroundColor,
                            color: state.isSelected || state.isFocused ? 'rgba(0, 0, 0, 0.8)' : base.color,
                            borderRadius: '10px',
                            margin: '2px auto',
                            width: '98%',
                          }),
                          indicatorSeparator: () => ({ display: 'none' }),
                        }}
                      />

                      {/* Conditionally show the relevant subgroup’s options */}
                      {selectedPaxiOption && (
                        <div className={getSubgroupOptionsClass(method.name, selectedPaxiOption.value)}>
                          {paxiSubgroups
                            .find(sub => sub.subgroup === selectedPaxiOption.value)
                            ?.options.map((opt, optIndex) => (
                              <div
                                className={`paxiOption paxiOption${optIndex + 1}`}
                                key={optIndex}
                              >
                                <div className={`paxiOption${optIndex + 1}Container`}>
                                  <p className={`paxiBundlingOption${optIndex + 1}Text`}>
                                    {opt.description} ({opt.dimensions})
                                  </p>
                                  <p className="paxiBundlingPrice">{opt.price}</p>
                                </div>
                                <input
                                  type="number"
                                  min="1"
                                  placeholder="1"
                                  id="dashboardBundlingBox"
                                  value={bundlingRules[opt.value] ?? ""}
                                  onChange={(e) => handleInputChange(opt.value, e.target.value)}
                                  onBlur={(e) => {
                                    if (e.target.value === "" || parseInt(e.target.value, 10) < 1) {
                                      setBundlingRules(prev => ({ ...prev, [opt.value]: "1" }));
                                    }
                                  }}
                                />
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                );
              }

              // If there's any other method not covered, return null or handle accordingly
              return null;
            })}
          </div>
        }
      </div>
    </div>
  );
};

export default BundlingDiv;
