// VendorFormStep1.jsx
import React, { useRef, useEffect, useContext, useState, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { VendorFormContext } from '../context/VendorFormContext.jsx';
import {
  onAuthStateChanged,
  getAuth
} from "firebase/auth";

import { doc, updateDoc, setDoc, getDoc, collection, query, where, getDocs } from '@firebase/firestore';
import { auth, db } from "../firebase";
import { motion, AnimatePresence } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';

import infoIcon from '../icons/infoIcon.png';
import './StorelessUser.css';

const VendorFormStep1 = ({ next, setAlertMessage, setAlertType }) => {
  const { formData, updateFormData } = useContext(VendorFormContext);
  const navigate = useNavigate();
  const addressContainerRef = useRef(null);
  const businessInfoDiv2 = useRef();

  const [storeNameExistsDiv, setStoreNameExistsDiv] = useState(false);
  const [storeNameReserved, setStoreNameReserved] = useState(false); // New state for reserved store names
  const [offeringType, setOfferingType] = useState(formData.offeringType || ''); // 'services' or 'products'
  const [userEmail, setUserEmail] = useState('');

  // State to control the visibility of the services message and icon
  const [messageVisible, setMessageVisible] = useState(false);
  const [showIcon, setShowIcon] = useState(false);

  // New State Variables for Address Autocomplete
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [isSuggestionsVisible, setIsSuggestionsVisible] = useState(false);

  const addressInputRef = useRef(null); // Ref for the address input field

  // Debounce Timer to prevent excessive API calls
  const debounceTimer = useRef(null);

  // Firebase Auth Instance
  const authInstance = getAuth();

  // Define Cloud Function URLs (Ensure these are correctly set up)
  const MAPBOX_SUGGESTIONS_URL = "https://mapboxgeocoding-a6vcfarowq-uc.a.run.app/get-address-suggestions";
  const MAPBOX_REVERSE_URL = "https://mapboxgeocoding-a6vcfarowq-uc.a.run.app/get-address-from-coordinates";

  // List of reserved subdomain names based on App.jsx routes
  const reservedStoreNames = useMemo(() => ([
    'scout',
    'vendor',
    'cart',
    'favourites',
    'market',
    'dashboard',
    'admin',
    'create-store',
    'addproduct',
    'wallet',
    // Add more reserved names here if needed
  ]), []);

  useEffect(() => {
    if (formData.offeringType === 'services') {
      triggerMessage();
    } else {
      setMessageVisible(false);
      setShowIcon(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.offeringType]);

  const categoryOptions = [
    { label: "Antiques & Collectables", value: "Antiques & Collectables" },
    { label: "Art", value: "Art" },
    { label: "Audio & Media", value: "Audio & Media" },
    { label: "Automotive & DIY", value: "Automotive & DIY" },
    { label: "Beauty", value: "Beauty" },
    { label: "Books", value: "Books" },
    { label: "Cellphones & Wearables", value: "Cellphones & Wearables" },
    { label: "Crafts & Handmade Goods", value: "Crafts & Handmade Goods" },
    { label: "Electronics", value: "Electronics" },
    { label: "Fashion", value: "Fashion" },
    { label: "Gaming", value: "Gaming" },
    { label: "Garden, Pool & Patio", value: "Garden, Pool & Patio" },
    { label: "Health, Fitness & Personal Care", value: "Health, Fitness & Personal Care" },
    { label: "Hobbies & Toys", value: "Hobbies & Toys" },
    { label: "Holistic & Esoteric", value: "Holistic & Esoteric" },
    { label: "Home & Decor", value: "Home & Decor" },
    { label: "Movies & Television", value: "Movies & Television" },
    { label: "Music & Instruments", value: "Music & Instruments" },
    { label: "Office & Stationary", value: "Office & Stationary" },
    { label: "Pets", value: "Pets" },
    { label: "Physical Sport & Training", value: "Physical Sport & Training" },
    { label: "Other", value: "Other" },
  ];

  const customStyles = {
    container: (base) => ({
      width: '95%',
      position: 'relative',
      zIndex: 1001,
    }),
    control: (base, state) => ({
      ...base,
      position: 'relative',
      backgroundColor: '#f5f5f5',
      height: '30px',
      borderRadius: '10px',
      marginBottom: '10px',
      width: '100%',
      fontFamily: 'Poppins',
      fontSize: '13.5px',
      zIndex: 1001,
      boxShadow: '-2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45)',
      borderColor: 'none',
      border: 'none',
    }),
    menu: (base) => ({
      ...base,
      position: 'absolute',
      backgroundColor: '#f5f5f5',
      top: '20px',
      marginBottom: '-24px',
      borderRadius: '0px 0px 12px 12px',
      fontFamily: 'Poppins',
      fontSize: '12px',
      zIndex: 1000,
      paddingTop: '15px',
      overflow: 'scroll'    
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? '#FB890E' : (state.isFocused ? '#FFC55C' : base.backgroundColor),
      color: state.isSelected || state.isFocused ? 'rgba(0, 0, 0, 0.8)' : base.color,
      width: '98%',
      margin: '2px auto',
      boxSizing: 'border-box',
      overflow: 'hidden',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      textAlign: 'left',
    }),
    singleValue: (base) => ({
      ...base,
      color: '#333',
    }),
  };

  // Function to trigger the message animation
  const triggerMessage = () => {
    setMessageVisible(true);
    setShowIcon(false);

    // Hide the message after 10 seconds
    const timer = setTimeout(() => {
      setMessageVisible(false);
      setShowIcon(true);
    }, 10000); // 10,000 milliseconds = 10 seconds

    // Cleanup the timer when component unmounts or offeringType changes
    return () => clearTimeout(timer);
  };

  // Get the current user's email
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserEmail(user.email);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'phone') {
      // Remove all non-digit characters
      const digits = value.replace(/\D/g, '');
      // Limit to 10 digits
      const limitedDigits = digits.slice(0, 10);
      updateFormData({ [name]: limitedDigits });
    } else if (name === 'store') {
      // Trim leading and trailing spaces for store name
      const trimmedValue = value.trim();
      updateFormData({ [name]: trimmedValue });
    } else {
      updateFormData({ [name]: value });
  
      // If the address field is being updated, fetch suggestions
      if (name === 'address') {
        fetchAddressSuggestions(value);
      }
    }
  };
   

  // Function to fetch address suggestions via Cloud Function
  const fetchAddressSuggestions = (query) => {
    // Clear the previous timer
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    if (query.length < 3) {
      // If query is too short, clear suggestions
      setAddressSuggestions([]);
      setIsSuggestionsVisible(false);
      return;
    }

    // Set a new timer for debouncing
    debounceTimer.current = setTimeout(() => {
      const user = authInstance.currentUser;
      if (!user) {
        // Optionally, handle unauthenticated state
        setAddressSuggestions([]);
        setIsSuggestionsVisible(false);
        return;
      }

      // Retrieve ID Token
      user.getIdToken()
        .then((idToken) => {
          fetch(MAPBOX_SUGGESTIONS_URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${idToken}`, // Include ID Token
            },
            body: JSON.stringify({ query }),
          })
            .then(response => {
              if (!response.ok) {
                throw new Error(`Server responded with status ${response.status}`);
              }
              return response.json();
            })
            .then(data => {
              console.log("Received data from suggestions API:", data);
              if (data.suggestions) {
                setAddressSuggestions(data.suggestions);
                setIsSuggestionsVisible(true);
              } else {
                setAddressSuggestions([]);
                setIsSuggestionsVisible(false);
              }
            })
            .catch(error => {
              console.error("Error fetching address suggestions:", error);
              setAddressSuggestions([]);
              setIsSuggestionsVisible(false);
            });
        })
        .catch(error => {
          console.error("Error retrieving ID token:", error);
          setAddressSuggestions([]);
          setIsSuggestionsVisible(false);
        });
    }, 500); // 500ms debounce
  };

  // Handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    // Format the selected suggestion
    const formattedPlaceName = suggestion.place_name.replace(/,\s*South Africa$/, '');

    // Update the address in form data
    updateFormData({ address: formattedPlaceName });

    // Hide suggestions after selection
    setAddressSuggestions([]);
    setIsSuggestionsVisible(false);

    // Optionally, blur the input to remove focus
    if (addressContainerRef.current) {
      const input = addressContainerRef.current.querySelector('input');
      if (input) {
        input.blur();
      }
    }
  };

  // Handle click outside the suggestions dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (addressContainerRef.current && !addressContainerRef.current.contains(event.target)) {
        setIsSuggestionsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle offering type selection
  const handleOfferingType = (type) => {
    setOfferingType(type);
    updateFormData({ offeringType: type, industry: '' });
  };

  // Handle Next button
  const handleNext = (e) => {
    e.preventDefault();
    next();
  };

  // Check if store name exists or is reserved whenever formData.store changes
  useEffect(() => {
    const checkStoreName = async () => {
      const storeName = formData.store ? formData.store.trim().toLowerCase() : '';

      // Check if store name is reserved
      if (reservedStoreNames.includes(storeName)) {
        setStoreNameReserved(true);
      } else {
        setStoreNameReserved(false);
      }

      // If store name is empty or reserved, skip Firestore check
      if (!formData.store || storeNameReserved) {
        setStoreNameExistsDiv(false);
        return;
      }

      // Check Firestore for existing store name
      const storeQueryInstance = query(
        collection(db, "stores"),
        where("store", "==", formData.store)
      );
      try {
        const snapshot = await getDocs(storeQueryInstance);
        if (snapshot.size > 0) {
          setStoreNameExistsDiv(true);
        } else {
          setStoreNameExistsDiv(false);
        }
      } catch (error) {
        console.error("Error checking store name existence:", error);
      }
    };

    checkStoreName();
  }, [formData.store, reservedStoreNames, storeNameReserved]);

  const handleCreateAccount = async (e) => {
    e.preventDefault();

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log("Authenticated User:", user);

        const dbRef = doc(db, 'users', user.uid);

        // Generate a unique store ID
        const storeID = uuidv4();

        // Prepare the base data to be saved in the 'stores' collection
        const storeInfo = {
          email: user.email,
          user: user.displayName,
          userID: user.uid,
          phone: formData.phone,
          address: formData.address,
          store: formData.store,
          store_description: formData.store_description,
          storeID: storeID, // Use the generated storeID
          likes: 0,
          offeringType: formData.offeringType,
          createdBy: user.uid,
          createdAt: new Date(),
          services: formData.services
        };

        try {
          // Update the user document with storeInfo
          await updateDoc(dbRef, storeInfo);
        } catch (error) {
          console.error("Error updating user document:", error);
          setAlertMessage("Failed to update user information. Please try again.");
          setAlertType('error')
          return;
        }

        console.log("User info added to database:", storeInfo);

        // Create store document within the 'stores' collection
        const storeDbRef = doc(db, 'stores', storeID);

        try {
          await setDoc(storeDbRef, storeInfo);
        } catch (error) {
          console.error("Error creating store document:", error);
          setAlertMessage("Failed to create store. Please try again.");
          setAlertType('error')
          return;
        }

        console.log("Store has been successfully created in the database.");
        setAlertMessage("You have successfully created your store");
        setAlertType('success')
        navigate("/vendor");
      } else {
        console.error("No authenticated user found.");
        setAlertMessage("You must be logged in to create a store.");
        setAlertType('error')
      }
    });
  };

  // Validation: Check if all required fields are filled and store name is valid
  const isFormValid = offeringType &&
                    formData.store &&
                    formData.store_description &&
                    formData.phone.length === 10 && // Ensure exactly 10 digits
                    formData.address &&
                    !storeNameExistsDiv &&
                    !storeNameReserved && // Ensure store name is not reserved
                    (
                      (offeringType === 'services' && formData.services) ||
                      (offeringType === 'products' && formData.industry)
                    );

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (offeringType === 'services') {
      handleCreateAccount(e);
    } else if (offeringType === 'products') {
      handleNext(e);
    }
  }

  // Animation variants for the services message
  const messageVariants = {
    hidden: { y: -100, opacity: 0 },
    visible: { y: 0, opacity: 1 },
    exit: { y: -100, opacity: 0 },
  };

  // Animation variants for the form sections
  const formVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: { y: 0, opacity: 1 },
    exit: { y: 50, opacity: 0 },
  };

  return (
    <form onSubmit={handleFormSubmit} className="vendorFormStep1">
      {/* AnimatePresence handles the mounting and unmounting animations for the services message */}
      <AnimatePresence>
        {offeringType === 'services' && messageVisible && (
          <motion.div
            className="servicesMessage"
            variants={messageVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 1 }} // 1 second for fade in/out
          >
            We're <strong>not allowing services just yet,</strong> but please <strong>fill in the details</strong> below so we can <strong>contact you when we launch it.</strong>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="offeringTypeQuestion">
        <p>What are you selling?</p>
        <div className="offeringTypeBtnDiv">
          <div
            className={`offeringTypeButton ${offeringType === 'services' ? 'selected' : ''}`}
            onClick={() => handleOfferingType('services')}
          >
            services
          </div>
          <div
            className={`offeringTypeButton ${offeringType === 'products' ? 'selected' : ''}`}
            onClick={() => handleOfferingType('products')}
          >
            products
          </div>
        </div>
      </div>

      {/* Conditionally render the icon when message is not visible and offeringType is 'services' */}
      {offeringType === 'services' && showIcon && (
        <div className="storelessUserBottomDiv" onClick={triggerMessage}>
          <img  className="storelessUserBottomIcon" src={infoIcon} alt="Info Icon" /> {/* You can choose any icon you prefer */}
        </div>
      )}

      {/* AnimatePresence handles the mounting and unmounting animations for the form sections */}
      <AnimatePresence>
        {offeringType && (
          <motion.div
            className="formSections"
            variants={formVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.5, ease: 'easeOut' }}
          >
            <div className="createStore2Form">

              <div className="businessInfoDiv2" ref={businessInfoDiv2}>
                <input
                  type="text"
                  placeholder='Business Name'
                  id='businessName2'
                  name='store'
                  value={formData.store}
                  onChange={handleChange}
                  required
                />
                {storeNameExistsDiv && (
                  <div className="storeNameExistsDiv2">
                    Business name already exists, please pick another name.
                  </div>
                )}
                {storeNameReserved && (
                  <div className="storeNameReservedDiv2">
                    The business name you entered is reserved. Please choose a different name.
                  </div>
                )}
                {/* Conditionally render the select based on offeringType */}
                {offeringType === 'products' && (
                  <Select
                  options={categoryOptions}
                  value={categoryOptions.find(option => option.value === formData.industry)}
                  onChange={(selectedOption) => 
                    updateFormData({ industry: selectedOption?.value || '' })
                  }
                  placeholder="Select Industry"
                  styles={customStyles}
                  isClearable
                  required={offeringType === 'products'}
                  />
                )}
                
                <textarea
                  placeholder='Business Description'
                  name='store_description'
                  rows="6"
                  id='businessDescription2'
                  value={formData.store_description}
                  onChange={handleChange}
                  required
                />
                {offeringType === 'services' && (
                  <div className="servicesField">
                    <label htmlFor="servicesOffered">What services do you offer?</label>
                    <input
                      type="text"
                      id="servicesOffered"
                      name="services"
                      value={formData.services || ''}
                      onChange={handleChange}
                      placeholder="e.g., Hair-dressing, Nailtech, Consulting..."
                      required={offeringType === 'services'}
                    />
                  </div>
                )}
              </div>
            </div>

            <div 
              className="businessInfoDiv3"
              style={{ zIndex: isSuggestionsVisible ? 4 : 2 }}
            >
              {/* Conditionally render the email input when services are selected */}
              {offeringType === 'services' && (
                <input
                  type="email"
                  name="email"
                  value={userEmail}
                  id='vendorFormEmail'
                  placeholder='Email'
                  disabled
                />
              )}
             <input
              type="tel"
              name="phone"
              value={formData.phone || ''}
              onChange={handleChange}
              id="phoneNumber"
              placeholder="0XX XXX XXXX"
              pattern="^0\d{2}\s?\d{3}\s?\d{4}$"
              title="Enter a 10-digit phone number starting with 0."
              inputMode="numeric"
              required
            />

              {/* Address input with autocomplete */}
              <div className='addressAutocompleteDiv' ref={addressContainerRef}>                
                <input
                  type="text"
                  name="address"
                  id='address'
                  value={formData.address || ''}
                  placeholder='Physical Address'
                  onChange={handleChange}
                  required
                  autoComplete="off" // Disable browser's default autocomplete
                />
                {/* Suggestions */}
                {isSuggestionsVisible && addressSuggestions.length > 0 && (
                  <ul className="suggestionsList">
                    {addressSuggestions.map((suggestion) => (
                      <li
                        key={suggestion.id}
                        onClick={() => handleSuggestionClick(suggestion)}
                        className="suggestionItem"
                      > 
                        {suggestion.place_name.replace(/,\s*South Africa$/, '')}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="storelessUserBtnDiv">
        {offeringType === 'products' && (
          <button
            type="button"
            className="storelessUserNextBtn"
            onClick={handleNext}
            disabled={!isFormValid}
          >
            next
          </button>
        )}
        {offeringType === 'services' && (
          <button
            type="submit"
            className="storelessUserSubmitBtn"
            disabled={!isFormValid}
          >
            submit
          </button>
        )}      
      </div>
    </form>
  );
};

export default VendorFormStep1;
