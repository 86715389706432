import React, { useState, useRef, useEffect, useContext } from 'react';
import plusIcon from '../icons/plusIcon.png';
import deleteIcon from '../icons/deleteIcon.png';
import infoIcon from '../icons/infoIcon.png';
import vendorIcon from '../icons/vendorIcon.png';
import dashboardIcon from '../icons/dashboardIcon.png';
import { VendorFormContext } from '../context/VendorFormContext.jsx';
import './BusinessLogoDiv.css';

const BusinessLogoDiv = ({
  businessLogoDivRef,
  uploadDivRef,
  formData,
  preview,
  isUploading,
  percentage,
  error,
  setFile,
  setPreview,
  setError,
  handleDelete
}) => {
  const { updateFormData } = useContext(VendorFormContext);
  const [showInfo, setShowInfo] = useState(false);
  const inputRef = useRef(null);

  // Local state for whether the user indicates they have a logo
  const [hasUserLogo, setHasUserLogo] = useState(() => {
    if (formData.store_logo) {
      return formData.store_logo === 'defaultLogo' ? 'No' : 'Yes';
    }
    return null;
  });

  useEffect(() => {
    if (formData.store_logo) {
      setHasUserLogo(formData.store_logo === 'defaultLogo' ? 'No' : 'Yes');
    }
  }, [formData.store_logo]);

  // Reset the input value when no logo is present
  useEffect(() => {
    if (!(preview || formData.store_logo) && inputRef.current) {
      inputRef.current.value = '';
    }
  }, [preview, formData.store_logo]);

  return (
    <div className="uploadLogoMediaDiv" ref={businessLogoDivRef}>
      {/* Header with Info Icon */}
      <div className="uploadProductImagesHeader">
        upload brand logo
        <img
          src={infoIcon}
          alt="Info"
          className="logoInfoIcon"
          onClick={() => setShowInfo(!showInfo)}
        />
      </div>

      <div className="logoSelectionDiv">
        <p>Do you have a logo?</p>
        <div className="preselectFutureButtons">
          <button
            type="button"
            className={`logoAvailableBtn ${hasUserLogo === 'Yes' ? 'selected' : ''}`}
            onClick={() => {
              setHasUserLogo('Yes');
              if (formData.store_logo === 'defaultLogo') {
                updateFormData({ store_logo: '' });
              }
            }}
          >
            yes
          </button>
          <button
            type="button"
            className={`logoAvailableBtn ${hasUserLogo === 'No' ? 'selected' : ''}`}
            onClick={() => {
              setHasUserLogo('No');
              updateFormData({ store_logo: 'defaultLogo' });
            }}
          >
            no
          </button>
        </div>
      </div>

      {/* Only show the upload container if the user indicated they have a logo */}
      {hasUserLogo === 'Yes' && (
        <div className="uploadProductMediaContainer">
          {/* If there's no logo selected yet, show plus icon + label */}
          {!(preview || formData.store_logo) && (
            <label
              htmlFor="inputField2"
              className="addProductImagePlusBtn"
              style={{ margin: 0 }}
            >
              <img
                className="addProductImagePlusBtnIcon"
                src={plusIcon}
                alt="Upload Logo"
              />
            </label>
          )}

          {/* Hidden input for selecting the file */}
          <input
            type="file"
            id="inputField2"
            accept="image/*"
            onChange={(e) => {
              const selectedFile = e.target.files[0];
              if (selectedFile) {
                if (selectedFile.size > 5 * 1024 * 1024) {
                  setError('File size exceeds 5 MB. Please select a smaller file.');
                  e.target.value = '';
                  return;
                }
                setFile(selectedFile);
                setPreview(URL.createObjectURL(selectedFile));
                setError('');
              }
            }}
            style={{ display: 'none' }}
            required={!formData.store_logo}
            ref={inputRef}
          />

          {/* If logo exists (preview or store_logo), show it */}
          {(preview || formData.store_logo) && (
            <div
              ref={uploadDivRef}
              className="logoUploadPreviewDiv"
              style={{ position: 'relative' }}
            >
              <img
                src={preview || formData.store_logo}
                className="logoUploadPreview"
                alt="Uploaded Logo"
              />

              {/* Show a progress bar if uploading */}
              {isUploading && (
                <progress
                  className="progressBar"
                  value={percentage || 0}
                  max="100"
                  style={{ position: 'absolute', bottom: '10px' }}
                />
              )}

              {/* If not uploading, show delete icon */}
              {!isUploading && formData.store_logo && (
                <div id="imageUploadDeleteBtn" onClick={handleDelete}>
                  <img
                    src={deleteIcon}
                    alt="Delete Logo"
                    className="deleteIcon"
                    style={{
                      width: '15px',
                      height: '15px',
                      cursor: 'pointer',
                      position: 'absolute',

                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {/* Show any error message at the bottom */}
      {error && <div className="addProductErrorMessage">{error}</div>}

      {/* Optionally, you can show a message if "No" is selected */}
      {hasUserLogo === 'No' && (
        <div className="defaultLogoMessage">
          Default logo will be used.
        </div>
      )}

      {/* Info Popup */}
      {showInfo && (
        <div className="logoInfoPopup">
          <div className="logoInfoPopupContent">
            The logo can be changed in the{' '}
            <div className="logoInfoBtn">
              <img src={vendorIcon} alt="Vendor" className="logoInfoBtnIcon" />
              vendor page
            </div>{' '}
            within the{' '}
            <div className="logoInfoBtn">
              <img src={dashboardIcon} alt="Dashboard" className="logoInfoBtnIcon" />
              dashboard page
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BusinessLogoDiv;
