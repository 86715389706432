import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";
import { 
  addDoc, collection, doc, updateDoc, setDoc, getDoc, getDocs, 
  query, where, onSnapshot, deleteDoc, serverTimestamp
} from '@firebase/firestore';
import { 
  ref, getDownloadURL, getStorage, listAll, 
  uploadBytesResumable, deleteObject 
} from 'firebase/storage';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db, storage } from '../../firebase';
import Select from "react-select";
import { motion, AnimatePresence } from "framer-motion";

import './Dashboard.css';
import './StoreUploadedProducts.css'

import backArrow from '../../icons/backArrow.png';
import backArrow2 from '../../icons/returnArrowIcon.png';
import plusIcon from '../../icons/plusIcon.png';
import editIcon from '../../icons/editIcon.png';
import LongPress from '../../components/LongPress';
import BundlingDiv from './BundlingDiv';
import blankImage from '../../images/blankImage.png';

import LogoUploadPopup from './LogoUploadPopup';
import Login from '../../login/Login';
import '../../market/MarketProductCard.css'
import ScoutCard from '../../scout/ScoutCard';
import ImageGallery from '../../scout/ImageGallery'

import StoreUploadedProducts from './StoreUploadedProducts'; 
import StoreProductStatus from './StoreProductStatus'; 
import defaultStoreLogo from '../../images/defaultStoreLogo.png';


// NEW Import
import SocialMediaManager from './SocialMediaManager';  // <--- MAKE SURE PATH IS CORRECT

const Dashboard = (props) => {
  const navigate = useNavigate();
  
  const [data, setData] = useState([]);
  const [userID, setUserID] = useState(null);
  const [storeID, setStoreID] = useState(null);
  const [publishedProducts, setPublishedProducts] = useState([]);
  const [editingProducts, setEditingProducts] = useState([]);
  const [pendingProducts, setPendingProducts] = useState([]);
  const [sentProducts, setSentProducts] = useState([]);
  
  const [adminOrderData, setAdminOrderData] = useState([]); // NEW: Store admin order history data

  const [showPendingProducts, setShowPendingProducts] = useState(true);
  const [showSentProducts, setShowSentProducts] = useState(false);

  const [originalStoreName, setOriginalStoreName] = useState('');
  const [originalStoreDescription, setOriginalStoreDescription] = useState('');
  const [storeName, setStoreName] = useState('');
  const [storeDescription, setStoreDescription] = useState('');

  const [showInfo1, setShowInfo1] = useState(false);
  const [noProducts, setNoProducts] = useState(false);
  const [editingText, setEditingText] = useState(false);
  const [imageGrowthAnimation, setImageGrowthAnimation] = useState();
  const [colorArray, setColorArray] = useState([]);

  const [showPublishedProducts, setShowPublishedProducts] = useState(true);
  const [showEditingProducts, setShowEditingProducts] = useState(false);
  const [showLoginOverlay, setShowLoginOverlay] = useState(false);
  const [showTextDivMessage, setShowTextDivMessage] = useState(false);
  const [infoDivExpanded, setInfoDivExpanded] = useState(false);
  const [isLogoUploadPopupOpen, setIsLogoUploadPopupOpen] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(null); 
  const [isScoutCardVisible, setIsScoutCardVisible] = useState(false);
  const wideScreenBreakpoint = 670;
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= wideScreenBreakpoint);

  const [showEthicalChecksPopup, setShowEthicalChecksPopup] = useState(false);
  const [ethicalChecks, setEthicalChecks] = useState();
  const [ethicalChecksText, setEthicalChecksText] = useState('');

  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryThumbnails, setGalleryThumbnails] = useState([]);

  const [storeNameExists, setStoreNameExists] = useState(false);
  const [showStoreNameExistsPopup, setShowStoreNameExistsPopup] = useState(false);
  const [sellerDeliveryPreference, setSellerDeliveryPreference] = useState('');
  const [isEditingStoreName, setIsEditingStoreName] = useState(false);

  // ----------------------
  // NEW: State for Delete Notifications
  // ----------------------
  const [deleteNotifications, setDeleteNotifications] = useState([]);

  useEffect(() => {
    document.title = 'Dashboard | taizte™';
  }, []);

  // Fetch adminOrderHistory once storeID is known
  useEffect(() => {
    if (storeID) {
      const adminOrderHistoryRef = doc(db, "orderHistory", storeID);
      getDoc(adminOrderHistoryRef).then((snapshot) => {
        if (snapshot.exists()) {
          const orderData = snapshot.data().orderHistory || [];
          setAdminOrderData(orderData);
          console.log("Order History", orderData)
        }
      });
    }
  }, [storeID]);

  // Fetch delivery preferences
  useEffect(() => {
    if (storeID) {
      getDoc(doc(db, "stores", storeID)).then(docSnap => {
        if (docSnap.exists()) {
          setSellerDeliveryPreference(docSnap.data().sellerDeliveryPreference || 'both');
        }
      });
    }
  }, [storeID]);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= wideScreenBreakpoint);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Fetch products once we have userID and storeID
  useEffect(() => {
    let unsubscribe = () => {};
    if (userID && storeID) {
      unsubscribe = retrieveUserProducts();
    }
    return () => {
      if (unsubscribe && typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [userID, storeID]);

  const openEthicalChecksPopup = useCallback((eCheck, eCheckText) => {
    console.log(eCheck, eCheckText);
    setEthicalChecks(eCheck);
    setEthicalChecksText(eCheckText);
    setShowEthicalChecksPopup(true);
  });

  // Retrieve user products and merge adminOrderData if available
  const retrieveUserProducts = () => {
    if (!storeID) {
      console.warn("storeID is not set. Cannot retrieve products.");
      return () => {}; 
    }

    const orderHistoryRef = doc(db, "orderHistory", storeID);

    // Listener for order history
    const unsubscribeOrderHistory = onSnapshot(
      orderHistoryRef,
      (docSnap) => {
        if (!docSnap.exists()) {
          console.warn("No orderHistory document found for this storeID.");
          setPendingProducts([]);
          setSentProducts([]);
          setPublishedProducts([]);
          setEditingProducts([]);
          setNoProducts(true);
          return;
        }

        const orderHistoryData = docSnap.data().orderHistory || [];

        let pendingArr = [];
        let sentArr = [];

        // Iterate over each order in orderHistory
        orderHistoryData.forEach((order) => {
          const orderBuyerInfo = order.buyerInfo || {};
          const orderProducts = order.products || [];
          console.log(orderProducts);
          // Each product in orderHistory is considered sold
          orderProducts.forEach((product) => {
            const enrichedProduct = {
              ...product,
              productSold: true,
              buyerInfo: orderBuyerInfo,
            };

            // Classify products as pending (sold but not sent) or sent
            if (enrichedProduct.productSent === true) {
              sentArr.push(enrichedProduct);
            } else {
              pendingArr.push(enrichedProduct);
            }
          });
        });

        setPendingProducts(pendingArr);
        setSentProducts(sentArr);
        setNoProducts(pendingArr.length === 0 && sentArr.length === 0);
      },
      (error) => {
        console.error("Error fetching order history:", error);
      }
    );

    // Query for products
    const productQueryRef = query(
      collection(db, "products"),
      where("storeID", "==", storeID)
    );

    // Listener for published and editing products
    const unsubscribePublishedData = onSnapshot(
      productQueryRef,
      (querySnapshot) => {
        let publishedArr = [];
        let editingArr = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data();

          if (data.status === "published" && data.productSold !== true) {
            publishedArr.push(data);
          } else if (data.status === "editing") {
            editingArr.push(data);
          }
        });

        setPublishedProducts(publishedArr);
        setEditingProducts(editingArr);
      },
      (error) => {
        console.error("Error fetching products:", error);
      }
    );

    // Return a single unsubscribe function
    return () => {
      unsubscribeOrderHistory();
      unsubscribePublishedData();
    };
  };

  useEffect(() => {
    if (showTextDivMessage) {
      const timer = setTimeout(() => {
        setShowTextDivMessage(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showTextDivMessage]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log(user);
        setUserID(user.uid);
        setShowLoginOverlay(false);
  
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const storeDoc = await getDoc(doc(db, "stores", userData.storeID))
            if (storeDoc.exists()) {
              const storeData = storeDoc.data();
              console.log("Store data:", storeData);
              console.log("User data:", userData);
              setData(storeData);
              setStoreName(storeData.store);
              setOriginalStoreName(storeData.store);
              setStoreDescription(storeData.store_description);
              setOriginalStoreDescription(storeData.store_description);
              setStoreID(storeData.storeID);
            }
          } else {
            console.log("No such user document!");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        setShowLoginOverlay(true);
      }
    });
  
    return () => {
      unsubscribe();
    };
  }, []);

  const onLongPress = () => {
    console.log('longpress is triggered');
    setShowInfo1(true);
  };

  const onClick = () => {
    console.log('click is triggered');
  };

  const editWebsiteText = () => {
    setEditingText(true);
  };

  // This effect only runs the store name existence check when storeName, originalStoreName, or storeID changes.
  // It will not flag an error if the trimmed storeName is identical to the originalStoreName.
  useEffect(() => {
    const checkIfStoreNameExists = async () => {
      if (!storeName) {
        console.log("Store name is empty.");
        setStoreNameExists(false);
        setShowStoreNameExistsPopup(false);
        return;
      }
  
      const normalizedStoreName = storeName.trim().toLowerCase();
      const normalizedOriginalStoreName = originalStoreName.trim().toLowerCase();
  
      if (normalizedStoreName === normalizedOriginalStoreName) {
        setStoreNameExists(false);
        setShowStoreNameExistsPopup(false);
        return;
      }
  
      const storeQuery = query(
        collection(db, "stores"),
        where("store", "==", storeName.trim())
      );
  
      try {
        const snapshot = await getDocs(storeQuery);
        if (snapshot.size > 0) {
          const exists = snapshot.docs.some(doc => doc.data().storeID !== storeID);
          setStoreNameExists(exists);
          setShowStoreNameExistsPopup(exists);
        } else {
          setStoreNameExists(false);
          setShowStoreNameExistsPopup(false);
        }
      } catch (error) {
        console.error("Error checking store name existence:", error);
        setStoreNameExists(false);
        setShowStoreNameExistsPopup(false);
      }
    };
  
    checkIfStoreNameExists();
  }, [storeName, originalStoreName, storeID]);

  const saveTextEdits = async () => {
    // Trim the values for consistency.
    const trimmedStoreName = storeName.trim();
    const trimmedStoreDescription = storeDescription.trim();
    const trimmedOriginalStoreName = originalStoreName.trim();
  
    if (!trimmedStoreName) {
      props.setAlertMessage("Store name cannot be blank.");
      props.setAlertType("error");
      return;
    }
  
    // Only check for a name conflict if the user has changed the store name.
    if (trimmedStoreName !== trimmedOriginalStoreName && storeNameExists) {
      props.setAlertMessage("Store name already exists. Please choose another name.");
      props.setAlertType("error");
      return;
    }
  
    setEditingText(false);
  
    // Update the original values to match the newly saved ones.
    setOriginalStoreName(trimmedStoreName);
    setOriginalStoreDescription(trimmedStoreDescription);
  
    const dbRef = doc(db, "users", userID);
    const storeRef = doc(db, "stores", storeID);
  
    try {
      // Update the user's document and the store document.
      await updateDoc(dbRef, {
        store: trimmedStoreName,
        store_description: trimmedStoreDescription,
      });
  
      await updateDoc(storeRef, {
        store: trimmedStoreName,
        store_description: trimmedStoreDescription,
      });
  
      // Update any products that belong to this user.
      const productQuery = query(collection(db, "products"), where("userID", "==", userID));
      onSnapshot(productQuery, (querySnapshot) => {
        querySnapshot.forEach((docSnap) => {
          const productID = docSnap.data().productID;
          updateDoc(doc(db, "products", productID), {
            store: trimmedStoreName,
            store_description: trimmedStoreDescription,
          });
        });
      });
  
      // Update any favourites documents to reflect the new store name and description.
      const favQuerySnapshot = await getDocs(query(collection(db, "favourites")));
      favQuerySnapshot.forEach(async (favDoc) => {
        const favData = favDoc.data();
        const { liked_stores, liked_products } = favData;
        if (!liked_stores || !liked_products) return;
  
        const updatedLikedStores = liked_stores.map((likedStore) => {
          if (likedStore.storeID === storeID) {
            return {
              ...likedStore,
              store: trimmedStoreName,
              store_description: trimmedStoreDescription,
            };
          }
          return likedStore;
        });
  
        const updatedLikedProducts = liked_products.map((likedProduct) => {
          if (likedProduct.storeID === storeID) {
            return {
              ...likedProduct,
              store: trimmedStoreName,
              store_description: trimmedStoreDescription,
            };
          }
          return likedProduct;
        });
  
        await updateDoc(doc(db, "favourites", favDoc.id), {
          liked_products: updatedLikedProducts,
          liked_stores: updatedLikedStores,
        });
      });
  
      props.setAlertMessage("Store Information has been changed.");
      props.setAlertType("success");
    } catch (error) {
      console.error("Error saving text edits:", error);
      props.setAlertMessage("An error occurred while saving changes.");
      props.setAlertType("error");
    }
  };
  
  const cancelTextEdit = async () => {
    setEditingText(false);
    setStoreName(originalStoreName);
    setStoreDescription(originalStoreDescription);
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };

  const longPressEvent = LongPress(onLongPress, onClick, defaultOptions);

  // ------------------------------------------------
  //  Updated "deleteProduct" function to push a notification
  // ------------------------------------------------
  const deleteProduct = async (userProduct, productName, storeName) => {
    const productID = userProduct.productID;
    console.log("product ID " + productID);
  
    const storeRef = doc(db, "stores", storeID);
    
    try {
      const storeSnap = await getDoc(storeRef);
      if (storeSnap.exists()) {
        let product_prices = storeSnap.data().product_prices || [];
        product_prices = product_prices.filter(price => price !== userProduct.seller_price);
  
        const total = product_prices.reduce((acc, price) => acc + Number(price), 0);
        const average_price = product_prices.length > 0 ? total / product_prices.length : 0;
        const lowest_price = product_prices.length > 0 ? Math.min(...product_prices.map(Number)) : 0;
        const highest_price = product_prices.length > 0 ? Math.max(...product_prices.map(Number)) : 0;
  
        await updateDoc(storeRef, {
          product_prices: product_prices,
          average_price: average_price,
          lowest_price: lowest_price,
          highest_price: highest_price,
        });
      }
  
      const favouritesSnapshot = await getDocs(collection(db, "favourites"));
      favouritesSnapshot.forEach(async (favDoc) => {
        const favData = favDoc.data();
        const likedProducts = Array.isArray(favData.liked_products) ? favData.liked_products : [];
        const favUserID = favData.userID;
  
        if (likedProducts.some(product => product.productID === productID)) {
          const updatedLikedProducts = likedProducts.filter(product => product.productID !== productID);
          await updateDoc(favDoc.ref, { liked_products: updatedLikedProducts });
  
          const notificationRef = doc(collection(db, 'notifications'));
          await setDoc(notificationRef, {
            notificationType: 'productUpdate',
            message: `The product titled '${productName}' has been deleted by the store ${storeName}`,
            messageDate: new Date().toLocaleString(),
            messageDateNumeric: serverTimestamp(),
            messageID: notificationRef.id,
            readStatus: 'unread',
            storeName: storeName,
            productName: productName,
            storeID: storeID,
            userID: favUserID
          });
        }
      });
  
      await deleteDoc(doc(db, "products", userProduct.productID));
      console.log("Product deleted!");
  
      if (userProduct.image) {
        const imageURL = userProduct.image;
        let fileName = imageURL.slice(89, imageURL.lastIndexOf('?')).replace(/ /g, "%20");
        fileName = decodeURIComponent(fileName);
  
        const deleteRef = ref(storage, `product images/${fileName}`);
        await deleteObject(deleteRef);
        console.log("Image successfully deleted!");
      }

      const cartSnapshot = await getDocs(collection(db, "cart"));
      cartSnapshot.forEach(async (cartDoc) => {
        const cartData = cartDoc.data();
        const cartItems = Array.isArray(cartData.cart) ? cartData.cart : [];
        const cartUserID = cartData.userID;
  
        if (cartItems.some(item => item.productID === productID)) {
          const updatedCartItems = cartItems.filter(item => item.productID !== productID);
          await updateDoc(cartDoc.ref, { cart: updatedCartItems });
  
          const notificationRef = doc(collection(db, 'notifications'));
          await setDoc(notificationRef, {
            notificationType: 'productUpdate',
            message: `The product titled '${productName}' has been deleted by the store ${storeName} and has been removed from your cart`,
            messageDate: new Date().toLocaleString(),
            messageDateNumeric: serverTimestamp(),
            messageID: notificationRef.id,
            readStatus: 'unread',
            storeName: storeName,
            productName: productName,
            storeID: storeID,
            userID: cartUserID
          });
        }
      });

      // -----------------------------------------------
      // Trigger a new delete-notification popup
      // -----------------------------------------------
      setDeleteNotifications((prev) => {
        const id = Date.now(); // use timestamp as unique ID
        const newNotification = { id, productName };
        if (prev.length === 3) {
          return [...prev.slice(1), newNotification];
        }
        return [...prev, newNotification];
      });

    } catch (error) {
      console.error("Error deleting product:", error);
      props.setAlertMessage("An error occurred while deleting the product.");
      props.setAlertType("error");
    }
  };

  const adjustTextAreaHeight = (event) => {
    event.target.style.height = 'auto';
    event.target.style.height = `${event.target.scrollHeight}px`;
  };
  
  useEffect(() => {
    let timer;

    const resetTimer = () => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        handleLogout();
      }, 600000); // 10 minutes
    };

    const handleActivity = () => {
      resetTimer();
    };

    const events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
    events.forEach((event) => {
      window.addEventListener(event, handleActivity);
    });

    resetTimer();

    return () => {
      if (timer) clearTimeout(timer);
      events.forEach((event) => {
        window.removeEventListener(event, handleActivity);
      });
    };
  }, []);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log("User has been logged out due to inactivity.");
        setShowLoginOverlay(true);
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setIsScoutCardVisible(true);
    setImageGrowthAnimation(true);
  };

  const closeScoutCard = () => {
    setSelectedProduct(null);
    setIsScoutCardVisible(false);
  };

  const handleDominantColor = (dominantColor, index) => {
    setColorArray((prevColors) => {
      const newColors = [...prevColors];
      newColors[index] = dominantColor;
      return newColors;
    });
  };

  const handleEditProduct = (product) => {
    navigate('../addproduct', { 
      state: { 
        from: '/dashboard',
        isEditing: true, 
        productID: product.productID, 
        storeID: product.storeID 
      } 
    });
  };

  const openLogoUploadPopup = () => {
    setIsLogoUploadPopupOpen(true);
  };
  
  const closeLogoUploadPopup = () => {
    setIsLogoUploadPopupOpen(false);
  };
  
  const handleLogoUpdate = (newLogoURL) => {
    setData((prevData) => ({
      ...prevData,
      store_logo: newLogoURL,
    }));
  };  

  const openGallery = (images, thumbnails) => {
    setGalleryImages(images);
    setGalleryThumbnails(thumbnails);
    setIsGalleryOpen(true);
  };
  
  const closeGallery = () => setIsGalleryOpen(false);

  // Auto-remove each delete notification after 2 seconds
  useEffect(() => {
    if (deleteNotifications.length === 0) return;
    const timers = deleteNotifications.map((notif) => 
      setTimeout(() => {
        setDeleteNotifications((prev) => prev.filter((n) => n.id !== notif.id));
      }, 2000)
    );
    return () => {
      timers.forEach((timer) => clearTimeout(timer));
    };
  }, [deleteNotifications]);

  return (
    <div className="dashboardBody">
      <style>{'body { background-color: #C8B7D2; }'}</style>
      <div className="dashboardHeader">
        <div className="dashboardHeaderContainer1">
          <Link to="/vendor">
            <div className="dashboardBack">
              <img src={backArrow} className="dashboardBackIcon" alt="Back" />
            </div>
          </Link>
          <div className='dashboardHeadingDiv'>
            <h1 className='dashboardHeadingText'>dashboard</h1>
          </div>
        </div>
        {data.store_logo && data.store_logo !== 'defaultLogo' ? (
          <img
            src={data.store_logo}
            alt="Store Logo"
            className='dashboardLogo'
            onClick={openLogoUploadPopup}
            style={{ cursor: 'pointer' }}
            title="Click to change logo"
          />
        ) : (
          <div
            className='dashboardLogoPlaceholder'
            onClick={openLogoUploadPopup}
            style={{ cursor: 'pointer' }}
            title="Click to upload logo"
          >
            <img src={plusIcon} alt="Upload Logo" />
          </div>
        )}
      </div>

      <AnimatePresence>
        {isLogoUploadPopupOpen && (
          <LogoUploadPopup
            userID={userID}
            storeID={storeID}
            currentLogo={data.store_logo}
            onClose={closeLogoUploadPopup}
            onLogoUpdate={handleLogoUpdate}
            setAlertMessage={props.setAlertMessage}
            setAlertType={props.setAlertType}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showTextDivMessage &&
          <motion.div
            className="marketTextDivMessage"
            initial={{ y: -30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -30, opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            {infoDivExpanded ? 'Longpress the description box to collapse it.' : 'Longpress the description box to expand it.'}
          </motion.div>
        }
      </AnimatePresence>

      <div className="dashboardContent">
        <div className="storeInfo">
          <div className="storeInfoTextSection">
            {editingText ?
              <textarea 
                className="dashboardEditStoreName" 
                value={storeName} 
                onFocus={() => setIsEditingStoreName(true)}
                onBlur={() => setIsEditingStoreName(false)}
                onChange={(e) => setStoreName(e.target.value)}
              /> :
              <p className="dashboardStoreName">{storeName}</p>
            }
            {editingText ?
              <textarea
                rows="3"
                className="dashboardEditStoreDescription"
                value={storeDescription}
                onChange={(e) => setStoreDescription(e.target.value)}
                onInput={adjustTextAreaHeight}
              /> :
              <p className="dashboardStoreDescription">{storeDescription}</p>
            }
          </div>
          <AnimatePresence>
            {editingText && isEditingStoreName && showStoreNameExistsPopup && (
              <motion.div
                className="dashboardStoreNameExistsDiv"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.3 }}
              >
                Business name already exists, please pick another name.
              </motion.div>
            )}
          </AnimatePresence>
          {!editingText ?
            <img 
              className="storeEditInfo" 
              src={editIcon} 
              onClick={editWebsiteText} 
              alt="Edit Info"
            /> :
            <div className="editTextBtnDiv">
              <div 
                className="submitTextBtn" 
                onClick={saveTextEdits}
                disabled={storeNameExists}
              >
                submit
              </div>
              <div className="cancelTextBtn" onClick={cancelTextEdit}>cancel</div>
            </div>
          }
        </div>
        {props.country && props.country !== 'South Africa' && (
          <div className="countryUploadNote">
            Note that product <strong>purchasing is not available in {props.country} yet, </strong> 
            but feel free to test it.
          </div>
        )}

        {/* 
          IF user is dealing in physical products, show product sections.
          Otherwise (for services?), you can omit these sections 
        */}
        {data.offeringType !== 'services' && (
          <>
            <StoreUploadedProducts
              storeName={storeName}
              publishedProducts={publishedProducts}
              editingProducts={editingProducts}
              showPublishedProducts={showPublishedProducts}
              showEditingProducts={showEditingProducts}
              setShowPublishedProducts={setShowPublishedProducts}
              setShowEditingProducts={setShowEditingProducts}
              handleProductClick={handleProductClick}
              handleEditProduct={handleEditProduct}
              deleteProduct={deleteProduct}
              longPressEvent={longPressEvent}
            />
            
            <StoreProductStatus
              storeName={storeName}
              pendingProducts={pendingProducts}
              sentProducts={sentProducts}
              showPendingProducts={showPendingProducts}
              showSentProducts={showSentProducts}
              setShowPendingProducts={setShowPendingProducts}
              setShowSentProducts={setShowSentProducts}
              handleProductClick={handleProductClick}
              handleEditProduct={handleEditProduct}
              deleteProduct={deleteProduct}
              longPressEvent={longPressEvent}
              adminOrderData={adminOrderData}
              setAlertMessage={props.setAlertMessage}
              setAlertType={props.setAlertType}
            />

            <SocialMediaManager storeID={storeID} />

            <Outlet />
          </>
        )}

        <BundlingDiv
          storeID={storeID}
          userProducts={publishedProducts}
        />

      </div>

      <AnimatePresence>
        {isGalleryOpen && (
          <ImageGallery 
            image={selectedProduct?.images} 
            imageThumbnail={selectedProduct?.imageThumbnails}
            closeGallery={closeGallery}
          />
        )}
      </AnimatePresence>

      {isScoutCardVisible && selectedProduct && (
        <div className="marketCardPopupDiv">
          <div className="marketCardPopupContent">
            <div className="marketBackArrow" onClick={closeScoutCard}>
              <div className="marketCardBlurredBackground">
                <img src={backArrow2} alt="Close" id="marketBackArrowIcon" />
              </div>
            </div>
            <ScoutCard 
              page={'dashboard'}
              imageGrowthAnimation={imageGrowthAnimation}
              productID={selectedProduct.productID}
              data={selectedProduct}
              image={selectedProduct.images} 
              imageThumbnail={selectedProduct.imageThumbnails}
              onDominantColor={handleDominantColor}
              listed_price={selectedProduct.listed_price}
              title={selectedProduct.productName}
              store={storeName}
              storeID={storeID}
              description={selectedProduct.description}
              category={selectedProduct.category}
              userID={userID}
              bundling={selectedProduct.bundling}
              isWideScreen={isWideScreen}
              openEthicalChecksPopup={openEthicalChecksPopup}
              showTextDivMessage={showTextDivMessage}
              setInfoDivExpanded={setInfoDivExpanded}
              setShowTextDivMessage={setShowTextDivMessage}
              openGallery={openGallery}
            />
          </div>
        </div>
      )}

      {showLoginOverlay &&
        <motion.div
          className="dashboardLogin"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0, duration: 0.5 }}
        >
          <div className="dashboardLoginDiv">
            <div className="dashboardLoginTextDiv">
              Session expired due to inactivity. Please <strong>login</strong> again to continue.
            </div>
            <Login show={showLoginOverlay} />
          </div>
        </motion.div>
      }

      <div className="deleteNotificationsContainer">
        <AnimatePresence>
          {deleteNotifications.map((notif, index) => (
            <motion.div
              key={notif.id}
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -20, opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="deleteNotificationPopup"
              style={{ bottom: `${(deleteNotifications.length - 1 - index) * 60 + 20}px` }}
            >
              <strong>{notif.productName}</strong> has successfully been deleted
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Dashboard;
