// src/Layout.jsx

import { useRef, useState, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { doc, onSnapshot } from '@firebase/firestore';
import { auth, db } from './firebase';
import { motion } from "framer-motion";
import './Layout.css';

import homeIcon from './icons/homeIcon.png';
import scoutIcon from './icons/scoutIcon.png';
import marketIcon from './icons/marketIcon.png';
import vendorIcon from './icons/vendorIcon.png';
import cartIcon from './icons/cartIcon.png';
import popupIcon from './icons/popupIcon.png';

const Layout = (props) => {
  const navBar = useRef();
  const popup = useRef();
  const [showNavBar, setShowNavBar] = useState(true);
  const [showPopupBar, setShowPopupBar] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;
  const [activeIcon, setActiveIcon] = useState(pathname.substring(1) || "home");
  const veryWideScreenBreakpoint = 1250;
  const [isVeryWideScreen, setIsVeryWideScreen] = useState(window.innerWidth >= veryWideScreenBreakpoint);
  const [isHovered, setIsHovered] = useState(false);
  const [isNavBarPages, setIsNavBarPages] = useState(true);
  const [navBarReady, setNavBarReady] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const hideNavBarTimeoutRef = useRef(null);

  // This flag will disable the open transition when a route change occurs.
  const [disableOpenTransition, setDisableOpenTransition] = useState(false);

  const navigate = useNavigate();

  // Helper function to check if the current page is the homepage.
  const isHomepage = () => pathname === '/' || pathname === '';

  const preventDrag = (event) => {
    event.preventDefault();
  };

  // Start collapse timeout (only if not on the homepage)
  const startHideNavBarTimeout = () => {
    if (isHomepage()) return;
    hideNavBarTimeoutRef.current = setTimeout(() => {
      setShowNavBar(false);
      setShowPopupBar(true);
      props.navBarStateFunction(false);
    }, 10000);
  };

  const clearHideNavBarTimeout = () => {
    if (hideNavBarTimeoutRef.current) {
      clearTimeout(hideNavBarTimeoutRef.current);
      hideNavBarTimeoutRef.current = null;
    }
  };

  // Decide the navbar visibility for the current route.
  const decideNavBarVisibility = () => {
    if (isHomepage()) {
      setIsNavBarPages(true);
      setShowNavBar(true);
      setShowPopupBar(false);
      props.navBarStateFunction(true);
      clearHideNavBarTimeout();
      return;
    }
    const noNavBarPages = ['/addproduct', '/dashboard', '/wallet', '/create-store', '/create-store2'];
    const shouldShowNavBar = !noNavBarPages.includes(pathname);
    setIsNavBarPages(shouldShowNavBar);
    setShowNavBar(shouldShowNavBar);
    setShowPopupBar(!shouldShowNavBar);
    props.navBarStateFunction(shouldShowNavBar);

    clearHideNavBarTimeout();
    if (shouldShowNavBar) {
      startHideNavBarTimeout();
    }
  };

  // Update active icon and navBar visibility on route change.
  useEffect(() => {
    // Disable open transition during route changes.
    setDisableOpenTransition(true);
    const timer = setTimeout(() => setDisableOpenTransition(false), 50);

    const updateActiveIcon = () => {
      const path = pathname.substring(1);
      if (path === "") {
        setActiveIcon("home");
      } else if (["scout", "market", "vendor", "cart"].includes(path)) {
        setActiveIcon(path);
      }
    };
    updateActiveIcon();
    decideNavBarVisibility();
    setNavBarReady(true);

    return () => clearTimeout(timer);
  }, [pathname]);

  // Set initial window height and load handling.
  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const handleLoad = () => {
      setShowNavBar(true);
      props.navBarStateFunction(true);
      if (!isHomepage()) {
        startHideNavBarTimeout();
      } else {
        clearHideNavBarTimeout();
      }
      setNavBarReady(true);
    };

    window.addEventListener('load', handleLoad);
    return () => {
      clearHideNavBarTimeout();
      window.removeEventListener('load', handleLoad);
    };
  }, [pathname]);

  // Update if the screen is very wide.
  useEffect(() => {
    const handleResize = () => {
      setIsVeryWideScreen(window.innerWidth >= veryWideScreenBreakpoint);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Re-check navbar visibility on route change.
  useEffect(() => {
    decideNavBarVisibility();
    setNavBarReady(true);
  }, [pathname]);

  // Handle cart icon visibility.
  useEffect(() => {
    if (props.userID && props.userID.trim() !== "") {
      const cartRef = doc(db, 'cart', props.userID);
      const unsubscribe = onSnapshot(cartRef, (docSnap) => {
        if (docSnap.exists()) {
          const cartData = docSnap.data().cart;
          if (Array.isArray(cartData) && cartData.length > 0) {
            setShowCart(true);
          } else if (pathname === "/cart") {
            setShowCart(true);
          } else {
            setShowCart(false);
          }
        } else if (pathname === "/cart") {
          setShowCart(true);
        } else {
          setShowCart(false);
        }
      }, (error) => {
        console.error("Error onSnapshot cart:", error);
        if (pathname === "/cart") {
          setShowCart(true);
        } else {
          setShowCart(false);
        }
      });
      return () => unsubscribe();
    } else {
      if (pathname === "/cart") {
        setShowCart(true);
      } else {  
        setShowCart(false);
      }
    }
  }, [props.userID, pathname]);

  const handleMouseEnter = () => {
    clearHideNavBarTimeout();
    setIsHovered(true);
  };

  // Only trigger collapse on mouse leave if not on the homepage.
  const handleMouseLeave = () => {
    setIsHovered(false);
    if (!isHomepage()) {
      startHideNavBarTimeout();
    }
  };

  const removeNavBar = () => {
    setShowNavBar(false);
    setShowPopupBar(true);
    props.navBarStateFunction(false);
  };

  const handleShowNavBar = () => {
    setShowNavBar(true);
    setShowPopupBar(false);
    props.navBarStateFunction(true);
    if (!isHomepage()) {
      startHideNavBarTimeout();
    }
  };

  // Define your spring transition for collapsing.
  const springTransition = {
    type: "spring",
    stiffness: 200,
    damping: 25,
  };

  // Determine the open variant transition:
  // Use zero duration if disableOpenTransition is true, otherwise use the spring transition.
  const openTransition = disableOpenTransition ? { duration: 0 } : springTransition;

  // Define motion variants.
  const navBarVariants = {
    open: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: '5px',
      width: "fit-content",
      height: isVeryWideScreen ? "215px" : "44px",
      scale: 1,
      transition: openTransition,
    },
    closed: {
      scale: 0.5,
      transition: springTransition,
    },
    contracted: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "50px",
      height: "30px",
      transition: springTransition,
    }
  };

  // Update the favicon.
  const setFavicon = (href) => {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = href;
    document.head.appendChild(link);
  };

  // Handle icon clicks.
  const handleIconClick = (route, iconName) => {
    setFavicon('/favicon.ico');
    setActiveIcon(iconName);
    navigate(route);
  };

  return (
    <>
      {isNavBarPages && (
        <div
          className={`navBarParent${!showNavBar && !showPopupBar ? ' navBarHidden' : ''}${isVeryWideScreen ? ' veryWide' : ''}${!navBarReady ? ' hiddenNavBar' : ''}`}
          style={
            isVeryWideScreen
              ? { top: `10px` }
              : { bottom: `calc(1.25 * var(--vh))` }
          }
        >
          <motion.div
            initial={false} // Prevent re-animation on re-mount.
            className={`navBarContainer${isVeryWideScreen ? ' veryWide' : ''}`}
            variants={navBarVariants}
            animate={showNavBar ? "open" : (showPopupBar ? "contracted" : "closed")}
            style={{
              borderRadius: showNavBar ? "15px" : "10px",
              overflow: "hidden" // Prevent overlapping during animation.
            }}
          >
            {showNavBar ? (
              <motion.ul
                onClick={removeNavBar}
                ref={navBar}
                className={`navBar${isVeryWideScreen ? ' veryWide' : ''}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                initial={false}
              >
                <li id="navBarIcons"
                  onClick={() => handleIconClick("/", "home")}
                  className={activeIcon === "home" ? "active" : ""}
                >
                  <img src={homeIcon} alt="home icon" onDragStart={preventDrag} />
                </li>
                <li id="navBarIcons"
                  onClick={() => handleIconClick("/scout", "scout")}
                  className={activeIcon === "scout" ? "active" : ""}
                >
                  <img src={scoutIcon} alt="scout icon" onDragStart={preventDrag} />
                </li>
                {/*
                  Uncomment if needed:
                  <li id="navBarIcons"
                    onClick={() => handleIconClick("/market", "market")}
                    className={activeIcon === "market" ? "active" : ""}
                  >
                    <img src={marketIcon} alt="market icon" onDragStart={preventDrag} />
                  </li>
                */}
                <li id="navBarIcons"
                  onClick={() => handleIconClick("/vendor", "vendor")}
                  className={activeIcon === "vendor" ? "active" : ""}
                >
                  <img src={vendorIcon} alt="vendor icon" onDragStart={preventDrag} />
                </li>
                {showCart && (
                  <li id="navBarIcons"
                    onClick={() => handleIconClick("/cart", "cart")}
                    className={activeIcon === "cart" ? "active" : ""}
                  >
                    <img src={cartIcon} alt="cart icon" onDragStart={preventDrag} />
                  </li>
                )}
              </motion.ul>
            ) : null}

            {showPopupBar ? (
              <motion.button
                ref={popup}
                className={`popup${isVeryWideScreen ? ' veryWide' : ''}`}
                id={`popup${isVeryWideScreen ? 'veryWide' : ''}`}
                onClick={handleShowNavBar}
                initial={false}
              >
                <img src={popupIcon} alt="return navigation bar" className="popupIcon" onDragStart={preventDrag} />
              </motion.button>
            ) : null}
          </motion.div>
        </div>
      )}
      <Outlet />
    </>
  );
};

export default Layout;
