import React, { useState, useRef } from 'react';
import { QRCodeCanvas } from 'qrcode.react'; // <-- This module generates the QR code
import './SharePopup.css';
import closeIcon from '../icons/deleteIcon.png'
import linkIcon from '../icons/linkIcon.png'
import QRCodeIcon from '../icons/QRCodeIcon.png'

const SharePopup = ({ websiteUrl, copyToClipboard, onClose }) => {
  // State to track if the QR code should be shown
  const [showQRCode, setShowQRCode] = useState(false);
  // A ref to help locate the QR code canvas element for downloading
  const qrCodeContainerRef = useRef(null);

  // Handler for "Share Website Link" button
  const handleShareLink = () => {
    copyToClipboard();
    // Optionally, you could close the popup after copying:
    // onClose();
  };

  // Handler to show the QR code when the "Generate QR Code" button is clicked
  const handleGenerateQRCode = () => {
    setShowQRCode(true);
  };

  // Handler for downloading the QR code as a PNG image
  const handleDownloadQRCode = () => {
    // Find the <canvas> inside our container
    const canvas = qrCodeContainerRef.current?.querySelector('canvas');
    if (canvas) {
      const imageURL = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.href = imageURL;
      downloadLink.download = 'qrcode.png';
      downloadLink.click();
    }
  };

  return (
    <div className="sharePopupOverlay">
      <div className="sharePopupContent">
        <div className="sharePopupHeader">share</div>
        <img src={closeIcon} className="closeSharePopupBtn" onClick={onClose} />
        <div className="shareBtnGroup">
          <button className="shareButtonLink" onClick={handleShareLink}>
            copy website link
            <img src={linkIcon} className="shareButtonLinkIcon" />
          </button>
          <button className="shareButton" onClick={handleGenerateQRCode}>
            <div className="QRCodeDiv">
              generate QR code
              <img src={QRCodeIcon} className="shareButtonQRIcon" />
            </div>
            {showQRCode && (
              <div ref={qrCodeContainerRef} className="qrCodeContainer">
                <div style={{margin: '10px'}}>
                  <QRCodeCanvas value={websiteUrl} size={300} />
                </div>
                <button className="downloadQRCodeBtn" onClick={handleDownloadQRCode}>
                  download QR code
                </button>
              </div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SharePopup;
